import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import "./Swipeable.css";
import { useSession } from "../../contexts/SessionContext.js";
import { IoRefreshSharp } from "react-icons/io5/index.js";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa/index.js";
import swipeLikeImg from "../../assets/images/swipe_like.png";
import swipeDislikeImg from "../../assets/images/swipe_dislike.png";
import { getNoSwipesOneliner, getLoadPlacesOneliner } from "./oneLiners.js";
import { calculateDistance } from "../../utils/location.js";

import RatingStars from "../RatingStars/RatingStars.js";
import PriceLevel from "../PriceLevel/PriceLevel.js";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner.js";
import CustomButton from "../CustomButton/CustomButton.js";
import MatchFound from "../MatchFound/MatchFound.js";
import {
  apiGetNextPlaces,
  apiGetSession,
  apiSwipeLeft,
  apiSwipeRight,
} from "../../utils/apiCalls.js";
import {
  startGetNextPlacesListener,
  startGetSessionListener,
  startSwipeRightListener,
  startResetSwipesAlertListener,
  startSwipeLeftListener,
} from "../../utils/listeners.js";
import { combinePlacesUniquely } from "../../utils/places.js";

let distanceTime = "10s"; //used for calculating how long the remaining swipe animation must be when drag released
const swipeThreshold = 100; //distance in px swipe must exceed before being considered a swipe

const Swipeable = ({ setIsLoadingModalOpen, setLoadingModalText }) => {
  const {
    session,
    places,
    location,
    setPlaces,
    websocket,
    generateResponseId,
    checkResponseId,
    getDeviceId,
    setUser,
    setLocalConfig,
    setSession,
  } = useSession();
  const [animation, setAnimation] = useState("");
  const [displayLike, setDisplayLike] = useState("");
  const [displayDislike, setDisplayDislike] = useState("");
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [moveX, setMoveX] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [isLoadingNextPlaces, setIsLoadingNextPlaces] = useState(false);
  const [matchFound, setMatchFound] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadPlacesOneLiner, setLoadPlacesOneLiner] = useState(null);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [loadedImages, setLoadedImages] = useState([]);
  const [loadedUrls, setLoadedUrls] = useState([]);
  const noSwipesOneLiner = useRef();

  useEffect(() => {
    if (noSwipesOneLiner.current !== "") {
      noSwipesOneLiner.current = getNoSwipesOneliner();
    } else {
      noSwipesOneLiner.current = "";
    }
  }, [isLoadingImages]);

  //handle state change on places
  useEffect(() => {
    //load one liner while places are loading
    if (places.length === 0 && loadPlacesOneLiner === null) {
      setLoadPlacesOneLiner(getLoadPlacesOneliner());
    }
    if (places.length > 0) {
      //remove duplicates that are in loadedImages first
      const uniquePlaces = combinePlacesUniquely(places, loadedImages);
      //calculate distance via coordinates and sort places accordingly
      uniquePlaces.forEach((place) => {
        place.distance = 0; //default
        //calc distance if location available haversine function
        if (location) {
          place.distance = calculateDistance(location, {
            latitude: place.location.lat,
            longitude: place.location.lng,
          }).toFixed(1);
        } else place.distance = 0; //return 0 as default
      });

      //sort places with closest first in list
      const sortedPlaces = Array.isArray(uniquePlaces)
        ? [...uniquePlaces].sort(
            (a, b) => parseFloat(a.distance) - parseFloat(b.distance)
          )
        : [];

      // Function to load an image and return a promise
      const loadImage = (item) => {
        return new Promise((resolve) => {
          if (item.photo && !loadedUrls.includes(item.photo)) {
            const img = new Image();
            img.onload = () => {
              setLoadedUrls((urls) => [...urls, img.src]);
              setLoadedImages((prev) => [...prev, { ...item, photo: img.src }]);
              resolve(true);
              setIsLoadingImages(false);
            };
            img.onerror = () => {
              resolve(true); // Resolve with null or some default on error to prevent failing others
            };
            img.src = item.photo;
          } else resolve(true);
        });
      };

      //loop through places and perform loadImage on each one using a promise.all()
      Promise.all(sortedPlaces.map(loadImage))
        .then((results) => {
          //refresh session to obtain new pageToken
          const responseId = generateResponseId();
          const deviceId = getDeviceId();
          const sessionId = session.sessionId;
          setIsLoadingNextPlaces(false);
          setIsLoadingImages(false);
          startGetSessionListener({
            checkResponseId,
            responseId,
            setUser,
            setLocalConfig,
            setSession,
            websocket,
          })
            .then((data) => {
              if (!data?.session || !data?.user) {
                window.location = "/?alert=session_expired";
                setLoadedImages([]);
                setLoadedUrls([]);
              }
            })
            .catch((error) => {});
          apiGetSession({
            websocket,
            responseId,
            deviceId,
            sessionId,
          });
        })
        .catch((error) => {
          throw new Error(
            "Error 420: Failed to load images. Please try again."
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places]);

  const getNextIndex = (currentIndex) => {
    if (
      process.env.NODE_ENV === "development" &&
      process.env.REACT_APP_DEV_FAST_SWIPE === "enabled"
    ) {
      return currentIndex + 5;
    } else return currentIndex + 1;
  };

  //obtain next set of places using page_token in session if provided
  const getNextPlaces = () => {
    if (process.env.NODE_ENV !== "development") {
      ReactGA.event({
        category: "SWIPEABLE",
        action: "SWIPEABLE_GET_MORE_PLACES",
      });
    }
    const pageToken = session?.page_token;
    const responseId = generateResponseId();
    const deviceId = getDeviceId();
    const sessionId = session.sessionId;
    startGetNextPlacesListener({
      checkResponseId,
      responseId,
      websocket,
      setPlaces,
    });
    apiGetNextPlaces({
      websocket,
      responseId,
      deviceId,
      sessionId,
      pageToken,
    });
  };

  const handleTouchStart = (e) => {
    // Get the initial touch position
    const touch = e.touches[0];
    setStartX(touch.clientX);
    setCurrentX(touch.clientX); // Initialize currentX
    setDragging(true); // Start dragging
  };

  const handleTouchMove = (e) => {
    // Calculate the distance moved
    if (e.touches && e.touches.length === 1) {
      const touch = e.touches[0];
      setCurrentX(touch.clientX);
      setMoveX(currentX - startX);
      // Determine if a swipe is occurring
      const isSwiping = Math.abs(moveX) > 0;

      if (isSwiping) {
        // Do something with the swipe direction
        if (moveX > 0 && Math.abs(moveX) > swipeThreshold / 2) {
          setDisplayDislike("");
          setDisplayLike("display");
        } else if (moveX < 0 && Math.abs(moveX) > swipeThreshold / 2) {
          setDisplayLike("");
          setDisplayDislike("display");
        } else {
          setDisplayLike("");
          setDisplayDislike("");
        }
      }
    }
  };
  const touchStyle = dragging
    ? {
        transform: `translateX(${moveX}px)`,
      }
    : {
        transition: "transform 0.3s ease-out", // Smoothly animate back to original position
      };

  const handleTouchEnd = () => {
    // Calculate the direction of the swipe based on moveX
    setMoveX(currentX - startX);

    if (moveX > swipeThreshold) {
      //calculate remaining distance to slide
      distanceTime =
        ((window.innerWidth - Math.abs(moveX)) / window.innerWidth) * 1;
      document.documentElement.style.setProperty(
        "--swipe-distance-time",
        `${distanceTime}s`
      );
      // Swiped right
      swipeRight();
      document.documentElement.style.setProperty("--swipe-end", `${moveX}px`); //the point at which the user ended their swipe, so the card doesn't bounce back before sliding
      setAnimation("slide-right");
      setTimeout(() => setDisplayLike(""), distanceTime);
    } else if (moveX < -swipeThreshold) {
      distanceTime =
        ((window.innerWidth - Math.abs(moveX)) / window.innerWidth) * 1;
      document.documentElement.style.setProperty(
        "--swipe-distance-time",
        `${distanceTime}s`
      );
      // Swiped left
      swipeLeft();
      document.documentElement.style.setProperty("--swipe-end", `${moveX}px`);
      setAnimation("slide-left");
      setTimeout(() => setDisplayDislike(""), distanceTime);
    } else {
      // Not enough swipe, go back to start
      setDisplayLike("");
      setDisplayDislike("");
      setDragging(false);
      setMoveX(0);
      return;
    }

    //setDragging(false);
    // Continue from here after a slight delay
    setTimeout(() => {
      if (moveX > swipeThreshold || moveX < -swipeThreshold) {
        const index = getNextIndex(currentIndex);
        setCurrentIndex(index);
        setDragging(false);
        setDisplayLike("");
        setMoveX(0);
      }
      setAnimation(""); // This will reset the animation for the next card
    }, distanceTime * 1000);
  };

  //handle server queries when a place is liked
  const swipeRight = () => {
    if (process.env.NODE_ENV !== "development") {
      ReactGA.event({
        category: "SWIPE",
        action: "SWIPEABLE_SWIPE_RIGHT",
        label: JSON.stringify(loadedImages[currentIndex]),
      });
    }
    const responseId = generateResponseId();
    const deviceId = getDeviceId();
    const sessionId = session.sessionId;

    startSwipeRightListener({
      checkResponseId,
      responseId,
      websocket,
    })
      .then((data) => {
        //Check response from server to see if a match has been found:
        if (data?.match) {
          const placeId = data.place_id;
          //parse through places and get matching place
          //pass to MatchFound component to display animation
          const matchPlace = loadedImages.find(
            (item) => item.place_id === placeId
          );
          if (matchPlace) {
            if (process.env.NODE_ENV !== "development") {
              ReactGA.event({
                category: "MATCH_MADE",
                action: "SWIPEABLE_MATCH_MADE",
                lavebl: JSON.stringify(matchPlace),
              });
            }
            //handle showing the match
            setMatchFound(matchPlace);
            startResetSwipesAlertListener({ websocket })
              .then((event) => {})
              .catch((error) => {});
          }
        }
      })
      .catch((error) => {
        throw new Error("Error swiping right!");
      });

    apiSwipeRight({
      websocket,
      responseId,
      sessionId,
      deviceId,
      placeId: loadedImages[currentIndex].place_id,
    });
  };

  //handle server queries when a place is disliked
  const swipeLeft = () => {
    if (process.env.NODE_ENV !== "development") {
      ReactGA.event({
        category: "SWIPE",
        action: "SWIPEABLE_SWIPE_LEFT",
        label: JSON.stringify(loadedImages[currentIndex]),
      });
    }
    const responseId = generateResponseId();
    const deviceId = getDeviceId();
    const sessionId = session.sessionId;

    startSwipeLeftListener({
      checkResponseId,
      responseId,
      websocket,
    })
      .then((data) => {
        //Check response from server to see if a match has been found:
        if (data?.match) {
          const placeId = data.place_id;
          //parse through places and get matching place
          //pass to MatchFound component to display animation
          const matchPlace = loadedImages.find(
            (item) => item.place_id === placeId
          );
          if (matchPlace) {
            //handle showing the match
            setMatchFound(matchPlace);
            startResetSwipesAlertListener({ websocket })
              .then((event) => {})
              .catch((error) => {});
          }
        }
      })
      .catch((error) => {
        throw new Error("Error swiping right!");
      });

    apiSwipeLeft({
      websocket,
      responseId,
      sessionId,
      deviceId,
      placeId: loadedImages[currentIndex].place_id,
    });
  };

  return (
    <div className="Swipeable-container">
      {matchFound && (
        <MatchFound
          match={matchFound}
          setIsLoadingModalOpen={setIsLoadingModalOpen}
          setLoadingModalText={setLoadingModalText}
        />
      )}
      {!isLoadingImages && currentIndex < loadedImages.length && (
        <div
          className={`swipeable ${animation}`}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={touchStyle}
        >
          {loadedImages.map((item, index) => (
            <div
              key={item.place_id}
              className={`swipeable-card ${
                index === currentIndex ? "active" : ""
              } ${animation}`}
              style={{
                zIndex: loadedImages.length - index,
              }}
            >
              <div className="Swipeable-card-spinner-backdrop">
                <div className="Swipeable-card-spinner">
                  <LoadingSpinner
                    isLoading={true}
                    width="100px"
                    height="100px"
                  />
                </div>
              </div>
              <img
                src={item.photo}
                alt={item.name}
                className="swipeable-main-image"
              />
              <div className="swipeable-swipe-container">
                <img
                  src={swipeLikeImg}
                  alt="like"
                  className={`swipeable-swipe-like ${displayLike}`}
                />
                <img
                  src={swipeDislikeImg}
                  alt="dislike"
                  className={`swipeable-swipe-dislike ${displayDislike}`}
                />
              </div>
              <div
                className="swipeable-card-info tutorial-swipeable-card-info"
                onClick={() => {
                  if (process.env.NODE_ENV !== "development") {
                    ReactGA.event({
                      category: "SWIPEABLE",
                      action: "SWIPEABLE_MORE_INFO_OPENED",
                      label: JSON.stringify(item.name),
                    });
                  }
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      item.formatted_address
                    )}&query_place_id=${item.place_id}`,
                    "_blank"
                  );
                }}
              >
                <h2>{item.name}</h2>
                <div className="swipeable-rating-wrapper">
                  <span className="swipeable-rating">
                    <RatingStars rating={item.rating} />
                  </span>
                  <span className="swipeable-distance">
                    {item.distance} mi.
                  </span>
                  <span className="swipeable-price-level">
                    <PriceLevel priceLevel={item.price_level} />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {!isLoadingImages && currentIndex < loadedImages.length && (
        <div className="underneath-image">
          <div
            style={{
              position: "absolute",
              top: "50vh",
              left: "50vw",
              zIndex: "-1",
              transform: "translate(-50%)",
            }}
          >
            <LoadingSpinner isLoading={true} width="100px" height="100px" />
          </div>
          {getNextIndex(currentIndex) < loadedImages.length &&
          loadedImages.length !== 0 ? (
            <>
              <img
                src={loadedImages[getNextIndex(currentIndex)].photo}
                alt={loadedImages[getNextIndex(currentIndex)].name}
              />
            </>
          ) : (
            // Render a different component or JSX here when the condition is not met
            <></>
          )}
        </div>
      )}
      {currentIndex >= loadedImages.length &&
        loadedImages.length !== 0 &&
        !isLoadingImages &&
        session?.page_token &&
        session?.page_token !== "none" && ( //load the loading screen if a page token is available.
          <div className="swipeable-no-more-swipes" style={{ display: "flex" }}>
            <div>{noSwipesOneLiner.current}</div>
            <div>
              <hr style={{ border: "1px solid var(--primary-color)" }} />
              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                <LoadingSpinner
                  isLoading={isLoadingNextPlaces}
                  height="45px"
                  width="45px"
                />
                <CustomButton
                  variant="primary"
                  label="Load More"
                  size="med"
                  onClick={() => {
                    noSwipesOneLiner.current = getNoSwipesOneliner();
                    setIsLoadingNextPlaces((x) => !x); //show loading spinner
                    getNextPlaces(); //get next set of places and append them to the places array
                  }}
                  style={{
                    display: isLoadingNextPlaces ? "none" : "block",
                  }}
                />
              </div>
              <div>{isLoadingNextPlaces ? loadPlacesOneLiner : ""}</div>
            </div>
          </div>
        )}
      {currentIndex >= loadedImages.length &&
        loadedImages.length !== 0 &&
        !isLoadingImages &&
        session?.page_token &&
        session?.page_token === "none" && (
          <div className="swipeable-no-more-swipes" style={{ display: "flex" }}>
            <div>{noSwipesOneLiner.current}</div>
            <div
              onClick={() => {
                setCurrentIndex(0);
              }}
            >
              <hr style={{ border: "1px solid var(--primary-color)" }} />
              <IoRefreshSharp className="swipeable-no-more-swipes-icon" />
              <div>Swipe Again</div>
            </div>
          </div>
        )}
      {!isLoadingImages && currentIndex < loadedImages.length && (
        <>
          <FaThumbsDown
            className="Swipeable-swipe-arrow-left"
            onClick={() => {
              distanceTime =
                (((window.innerWidth - Math.abs(moveX)) / window.innerWidth) *
                  1) /
                2;
              document.documentElement.style.setProperty(
                "--swipe-distance-time",
                `${distanceTime}s`
              );
              setTimeout(() => {
                const index = getNextIndex(currentIndex);
                setCurrentIndex(index);
                setDragging(false);
                setDisplayLike("");
                setMoveX(0);
                setAnimation(""); // This will reset the animation for the next card
              }, distanceTime * 1000);
              swipeLeft();
              setAnimation("slide-left");
              setTimeout(() => setDisplayDislike(""), distanceTime);
            }}
          />

          <FaThumbsUp
            className="Swipeable-swipe-arrow-right"
            onClick={() => {
              distanceTime =
                (((window.innerWidth - Math.abs(moveX)) / window.innerWidth) *
                  1) /
                2;
              document.documentElement.style.setProperty(
                "--swipe-distance-time",
                `${distanceTime}s`
              );
              setTimeout(() => {
                const index = getNextIndex(currentIndex);
                setCurrentIndex(index);
                setDragging(false);
                setDisplayLike("");
                setMoveX(0);
                setAnimation(""); // This will reset the animation for the next card
              }, distanceTime * 1000);
              swipeRight();
              setAnimation("slide-right");
              setTimeout(() => setDisplayLike(""), distanceTime);
            }}
          />
        </>
      )}
      {isLoadingImages && (
        <div className="Swipeable-loading-spinner">
          <LoadingSpinner
            isLoading={isLoadingImages}
            height="75px"
            width="75px"
          />
          <div className="Swipeable-loading-spinner-text">
            {loadPlacesOneLiner}
          </div>
        </div>
      )}
    </div>
  );
};

export default Swipeable;
