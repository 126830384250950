// Importing necessary React hooks and components to work with.
import React, { useState } from "react"; // Import React and a hook called useState for managing state.
import "./QRCodeScanner.css";
import { useNavigate } from "react-router-dom"; // Hook for navigating programmatically between routes.
import WebcamCapture from "./WebcamCapture"; // A custom component for capturing webcam input.
import jsQR from "jsqr"; // A library for scanning QR codes from images.

// The QRCodeScanner component, designed to scan QR codes and navigate based on their content.
const QRCodeScanner = ({ onClose }) => {
  const navigate = useNavigate(); // Enables navigation to other routes.
  const [qrCode, setQrCode] = useState(""); // State to store the detected QR code's data.

  // Function to handle the QR code scanning process.
  const handleScan = (imageSrc) => {
    if (imageSrc) { // Checks if there is an image source provided.
      const image = new Image(); // Creates a new HTML image element.
      image.src = imageSrc; // Sets the source of the image to the provided imageSrc.
      image.onload = () => { // Defines what to do once the image has loaded.
        const canvas = document.createElement("canvas"); // Creates a canvas element.
        canvas.width = image.width; // Sets the canvas width to the image's width.
        canvas.height = image.height; // Sets the canvas height to the image's height.
        const ctx = canvas.getContext("2d"); // Gets the 2D drawing context for the canvas.
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height); // Draws the image onto the canvas.
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height); // Extracts the pixel data of the image.
        const code = jsQR(imageData.data, imageData.width, imageData.height, { // Scans the image data for a QR code.
          inversionAttempts: "dontInvert", // Tells the scanner not to invert the image colors.
        });
        if (code) { // If a QR code was found.
          setQrCode(code.data); // Updates the qrCode state with the data from the QR code.
          const urlObj = new URL(code.data); // Parses the QR code data as a URL.
          const params = new URLSearchParams(urlObj.search); // Extracts search parameters from the URL.
          const sessionId = params.get('sessionId'); // Retrieves the session ID from the parameters.
          navigate(`/swipe?joinSession=true&sessionId=${sessionId}`); // Navigates to a specific route, including the session ID.
        }
      };
    }
  };

  // The render part of the component, displaying the webcam capture and the scanned QR code (if any).
  return (
    <div
    className="QRCodeScanner-backdrop"
      onClick={() => {
        onClose(visible => !visible); // Toggles visibility when the div is clicked.
      }}
    >
      <div>
        <WebcamCapture onScan={handleScan} /> {/* Incorporates the WebcamCapture component with the scanning handler. */}
      </div>
      {qrCode && <p>QR Code: {qrCode}</p>} {/* Conditionally renders the QR code text if qrCode is not empty. */}
    </div>
  );
};

export default QRCodeScanner; // Makes the QRCodeScanner component available for import in other files.
