import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid"; // Import the uuid library
import WebSocketHandler from "../utils/websocketHandler";

// Create Context Object
export const SessionContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(false); // For storing current session information
  const [user, setUser] = useState(null); // For storing current users information based on deviceId
  const [users, setUsers] = useState(null); // For storing a list of user IDs of users in the session, connected
  const [places, setPlaces] = useState([]); // For storing list of places
  const [location, setLocation] = useState(null); // For storing the current devices location if allowed
  const [websocket, setWebsocket] = useState(null); // For storing the websocketHandler to be used throughout the session
  const [isConnected, setIsConnected] = useState(false);
  const reconnectTimeout = useRef(1000);
  const isReconnecting = useRef(false);
  const reconnectAttempts = useRef(0);

  useEffect(() => {
    console.log(websocket);
  }, [websocket])

  useEffect(() => {
    const url = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_API_WEBSOCKET_WSS : process.env.REACT_APP_API_WEBSOCKET_WSS;
    setWebsocket(
      new WebSocketHandler({ url })
    );
    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => websocket?.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !websocket?.isConnected() &&
      typeof websocket?.isConnected === "function"
    ) {
      connectSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocket]);

  const createDeviceId = () => {
    const deviceId = uuidv4();
    setLocalConfig("deviceId", deviceId);
    return deviceId;
  };

  const getDeviceId = () => {
    let deviceId = getLocalConfig("deviceId");
    if (!deviceId) {
      deviceId = createDeviceId();
    }
    return deviceId;
  };

  const startSession = () => {
    resetSession();
    return createDeviceId();
  };

  const resetSession = () => {
    removeLocalConfig("deviceId"); //remove deviceId from localstorage
    removeLocalConfig("session"); //remove session info from localstorage
    setSession(null);
    setUser(null);
    setUsers(null);
    return true;
  };

  const generateResponseId = () => {
    return uuidv4();
  };

  const checkResponseId = ({ event, responseId }) => {
    return new Promise((resolve, reject) => {
      try {
        const data = JSON.parse(event);
        if (data?.responseId === responseId) {
          if (process.env.NODE_ENV === "development") {
            console.log(`DEV::RESPONSE_PROCESSED: ${responseId}`);
          }
          resolve(data);
          return data;
        }
      } catch (error) {
        //parsing failed
        resolve(false);
        return false;
      }
    });
  };

  //connect and register the listeners
  // open listener for sending session request
  // responseId listener for session request
  const connectSocket = () => {
    //setup open listener for updating connection state
    const openListener = () => {
      //create open Listener for updating state of connection
      isReconnecting.current = false; //connected/reconnected
      setIsConnected(true); //update state
      websocket.removeEventListener("open", openListener);
      websocket.removeEventListener("open", keepAlive);
    };
    const keepAlive = () => {
      let timer;
      if (websocket.isConnected)
        // eslint-disable-next-line no-unused-vars
        timer = setInterval(() => {
          websocket.sendMessage({ action: "ping" });
        }, 60000); //Keep Alive Timer
    };
    websocket.addEventListener("open", openListener);
    websocket.addEventListener("open", keepAlive);
    //setup close listener for updating connection state
    const closeListener = () => {
      setIsConnected(false);
      attemptReconnect();
      websocket.removeEventListener("open", keepAlive);
      websocket.removeEventListener("close", closeListener);
      websocket.close();
    };
    websocket.addEventListener("close", closeListener);
    //setup error handler to clean listeners on error
    const errorListener = () => {
      websocket.removeEventListener("open", openListener);
      websocket.removeEventListener("open", keepAlive);
      websocket.removeEventListener("error", errorListener);
    }
    websocket.addEventListener("error", errorListener);
    websocket.connect({}); //connect to websocket
  };

  const attemptReconnect = () => {
    if (!isConnected && !isReconnecting.current) {
      isReconnecting.current = true;
      // Max 15 seconds, doubles on every attempt until max
      const x = reconnectTimeout.current;
      reconnectTimeout.current = x * 2 < 15000 ? x * 2 : 15000; //max 15 seconds on reconnect timer
      if (process.env.NODE_ENV === "development") {
        console.log(`DEV::WEBSOCKET:RECONNECT:#${reconnectAttempts.current} Timer: ${reconnectTimeout.current}`);
      }
      setTimeout(() => {
        reconnectAttempts.current = reconnectAttempts.current + 1;
        connectSocket(); // Attempt to reconnect
        isReconnecting.current = false; //reset for next iteration
      }, reconnectTimeout);
    }
  };

  //used to store settings and information locally
  const setLocalConfig = (key, value) => {
    return localStorage.setItem(`dither_local_${key}`, value);
  };
  //used to get settings and information locally
  const getLocalConfig = (key) => {
    return localStorage.getItem(`dither_local_${key}`);
  };
  const removeLocalConfig = (key) => {
    return localStorage.removeItem(`dither_local_${key}`);
  };

  const getLocalSession = () => {
    const session = getLocalConfig("session");
    try {
      return JSON.parse(session);
    } catch (error) {
      return false;
    }
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
        user,
        setUser,
        users,
        location,
        setLocation,
        websocket,
        isConnected,
        setIsConnected,
        createDeviceId,
        getDeviceId,
        startSession,
        resetSession,
        connectSocket,
        generateResponseId,
        checkResponseId,
        setLocalConfig,
        getLocalConfig,
        getLocalSession,
        places,
        setPlaces,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

// Create a hook to use the SessionContext, this is just for convenience ( const {sessionData, setSessionData} = useSession() )
export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
