import React, { useRef, useEffect } from "react";
import "./App.css";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SessionProvider } from "./contexts/SessionContext";

//import screens
import LandingScreen from "./screens/LandingScreen/LandingScreen";
import SwipeScreen from "./screens/SwipeScreen/SwipeScreen";
import NoInternetBlocker from "./components/NoInternetBlocker/NoInternetBlocker";


// Initialize GA
if (process.env.NODE_ENV !== "development") ReactGA.initialize('G-RSFVNTKXNX');

function App() {
  //handle google analytics
  const isAnalyzed = useRef(false);
  useEffect(() => {
    if (!isAnalyzed.current && process.env.NODE_ENV !== "development") {
      isAnalyzed.current = false;
      ReactGA.event({
        category: "LANDING",
        action: "LANDING_USER_LANDED",
      });
    }
  }, [])
  return (
    <SessionProvider>
      <NoInternetBlocker />
      <ErrorBoundary>
          <div className="App">
            <Router>
              <Routes>
                <Route path='/' element={<LandingScreen />} />
                <Route path='/swipe' element={<SwipeScreen />} />
              </Routes>
            </Router>
          </div>
      </ErrorBoundary>
    </SessionProvider>
  );
}

export default App;
